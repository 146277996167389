<div class="row pt-1 mb-2">
<!--
  <div class="col-md-3 text-center mb-2">
    <h1 class="h5">
      <i class="fas fa-money-bill-wave"></i> {{ solde }}
    </h1>
  </div>
  <div class="col-md-3 text-center text-danger">
    <h2 class="h5">
      <i class="fab fa-angular fa-lg ml-2 mr-1 text-danger"></i>{{ articlesVendus }}
    </h2>
  </div>
  <div class="col-md-3 text-center text-primary">
    <h2 class="h5">
      <i class="fab fa-bootstrap fa-lg ml-2 mr-1 text-primary"></i>{{ awaitingCMD }}
    </h2>
  </div>
  <div class="col-md-3 text-center text-warning">
    <h2 class="h5">
      <i class="fab fa-font-awesome-flag fa-lg ml-2 mr-1 text-warning"></i>{{ cmdFourniss }}
    </h2>
  </div>
-->
</div>

<hr>
<div class="row mb-1">
  <div class="col-md-12 text-center">
  </div>
</div>

<div class="row pt-2 pb-4">
  <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4" *ngFor="let item of features">
    <div class="card card-cascade">
      <div class="card-img">
        <a routerLink="/{{ item.link }}">
          <img class="card-img-top" src="./assets/params/images/showcase/{{ item.image }}" alt="Card image cap">
        </a>
      </div>
      <div class="card-body pt-0">
<!--        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
            <a routerLink="/{{ item.link }}" class="text-primary">
              <h6 class="font-bold pb-1">
                <i class="{{ item.icon }}"></i> {{ item.type }}</h6>
            </a>
          </div>
        </div>-->
        <h6 class="card-title">
          <span style="font-size: 14px;"><strong>{{ item.description }}
            </strong></span>
        </h6>
      </div>
    </div>
  </div>
</div>

<!--  <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-2" *ngFor="let feature of features">
    <div class="card bg-light mb-3">
      <a routerLink="/{{ feature.link }}">
        <div class="card-header">
          <div class="row">
            <div class="col-10 col-xl-10">
              <h5 class="card-title">{{ feature.name }}</h5>
            </div>
            <div class="col-2 col-xl-2">
              <i class="fas {{ feature.icon }} fa-lg text-primary"></i>
            </div>
          </div>
        </div>
        <div class="card-body">
          <p class="card-text">{{ feature.description }}</p>
        </div>
      </a>
    </div>
  </div> -->