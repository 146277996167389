<div class="app" >

  <header>
    <nav class="navbar navbar-expand-md fixed-top navbar-dark " style="background-color: #0020602b;">
      <a class="navbar-brand" routerLink="/">
        <span class="mr-1" style="color:white"><img src="./assets/params/images/logo/logosmk.png" alt="Logo Angular starter" style="height: 3rem;"></span>
        
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
        aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>


    </nav>
  </header>

  <main>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>

